import React, { Component } from "react";
import { Redirect } from "react-router";
import TextInputGroup from "../../layout/TextInputGroup";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default class EditUser extends Component {
  constructor(props) {
    super(props);
    const row = props.location.state.data;

    this.state = {
      attestationID: row.attestationID,
      // tracking: row.trackingNumber,

      contact: row.conatctNumber,

      attestationtype: row.attestationType,

      statusID: row.statusID,
      attestationTypeList: [],
      attestionstatusList: [],
      attestationTypeID: row.attestationTypeID,
      startDate: row.receivedDate,
      name: row.name,
      status: row.status,
      redirect: false,
      success: false,
      error: {},
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, errors: false });
  };
  handleChange = (event) => {
    this.setState({
      startDate: event.target.value,
    });
  };
  fetchattestiontype = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/attestationType/getAttestationTypes"
      )
      .then((res) => {
        let attestionList = res.data.data;

        this.setState({ attestationTypeList: attestionList });
      })
      .catch((err) => {});
  };
  fetchstatus = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/attestationStatus/getAttestationStatus"
      )
      .then((res) => {
        let statusList = res.data.data;

        this.setState({ attestionstatusList: statusList });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.fetchstatus();
    this.fetchattestiontype();
  }
  onSubmit = (e) => {
    const {
      contact,
      name,
      startDate,
      status,

      attestationID,

      statusID,

      attestationTypeID,
    } = this.state;

    e.preventDefault();
    let formIsValid = true;
    let newErrors = {};

    if (name.trim() === "") {
      newErrors = { ...newErrors, name: "Name is required" };
      formIsValid = false;
    }

    if (contact.trim() === "") {
      newErrors = { ...newErrors, contact: "Contact is required" };
      formIsValid = false;
    }

    if (status === "") {
      newErrors = { ...newErrors, status: "Status is required" };
      formIsValid = false;
    }

    if (!formIsValid) {
      this.setState({
        error: newErrors,
      });
      return;
    }
    let req = {
      attestationID: attestationID,
      statusID: statusID,
      attestationTypeID: attestationTypeID,
      name: name,

      receivedDate: startDate,
      conatctNumber: contact,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "/attestation/updateAttestationRecord",
        req
      )
      .then((res) => {
        this.setState({ error: {}, success: true });
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 1000);
      })
      .catch((err) => {});
  };
  render() {
    const {
      name,

      contact,

      attestationTypeID,
      attestationTypeList,

      statusID,
      attestionstatusList,
      success,
      redirect,
      error,
    } = this.state;

    if (redirect) {
      return <Redirect push to={{ pathname: "/dashboard" }} />;
    }
    return (
      <section
        style={{ minHeight: "75vh" }}
        className="d-flex align-items-center my-auto p-5 bg-light"
      >
        <div className="container">
          <div className="row my-auto">
            <div className="col-md-4 my-auto ">
              <div className="section-title text-left col-md-11 mt-3 ">
                <h3>Edit Record</h3>
              </div>

              {success ? (
                <div
                  className=" text-left col-md-11 mb-3 mt-2"
                  style={{ color: "green", fontSize: "2.5vmin" }}
                >
                  Record Updated Successfully <i className="fas fa-check" />
                </div>
              ) : (
                <div className="text-muted text-left col-md-11 mb-3">
                  Please enter details
                </div>
              )}
            </div>

            <div className="col-md-8 mt-3">
              <div className="card mb-3 text-left">
                <div className="card-body ml100 mr100">
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <TextInputGroup
                          name="name"
                          value={name}
                          placeholder="Enter Name"
                          label="Name"
                          disabled={false}
                          onChange={this.onChange}
                          error={error.name}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <TextInputGroup
                          name="contact"
                          value={contact}
                          placeholder="Enter Contact"
                          label="Contact"
                          disabled={false}
                          onChange={this.onChange}
                          error={error.contact}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        {/* <br /> */}
                        <TextInputGroup
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          label="Select Date"
                          // style={{ width: "325px", height: "36px" }}
                          placeholder="select Date"
                          onChange={this.handleChange}
                          // dateFormat="yyyy-mm-dd"
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label htmlFor={attestationTypeID}>
                          Attestation Type
                        </label>
                        <select
                          onChange={this.onChange}
                          value={attestationTypeID}
                          name="attestationTypeID"
                          className={classnames(
                            "form-control form-control-md",
                            {
                              "is-invalid": error.attestationTypeID,
                            }
                          )}
                        >
                          <option value="">Select Attestation Type</option>
                          {attestationTypeList.map((item, index) => (
                            <option key={index} value={item.attestationTypeID}>
                              {item.attestationType}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label htmlFor={statusID}>Order Status</label>
                        <select
                          onChange={this.onChange}
                          value={statusID}
                          name="statusID"
                          className={classnames(
                            "form-control form-control-md",
                            {
                              "is-invalid": error.statusID,
                            }
                          )}
                        >
                          <option value="">Select Status</option>
                          {attestionstatusList.map((item, index) => (
                            <option key={index} value={item.statusID}>
                              {item.attestationStatus}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 col-sm-12 my-auto">
                        <button
                          className="btn btn-block"
                          onClick={() => {
                            this.setState({ redirect: true });
                          }}
                          style={{
                            backgroundColor: "#000428",
                            color: "white",
                            textTransform: "uppercase",
                            marginTop: "3vh",
                          }}
                        >
                          <i className="fas fa-times" /> Cancel
                        </button>
                      </div>
                      <div className="col-lg-6 col-sm-12 my-auto">
                        <button
                          className="btn btn-block"
                          onClick={this.onSubmit}
                          style={{
                            backgroundColor: "#000428",
                            color: "white",
                            textTransform: "uppercase",
                            marginTop: "3vh",
                          }}
                        >
                          <i className="fas fa-check" /> Update Record
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
