import React from "react";
import "./css/footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="pl-5 pr-5" style={{ color: "white" }}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <ul className="list-inline social-buttons"></ul>

          <div style={{ color: "white" }}>&copy; Copyright-2020</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
