import React, { Component } from "react";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import axios from "axios";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attestationDataArray: [],
    };
  }

  fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/attestation/getAttestationRecord")
      .then((res) => {
        let data = res.data.data.sort(
          (a, b) => b.attestationID - a.attestationID
        );

        this.setState({ attestationDataArray: data });
      })

      .catch((err) => {});
  };
  componentDidMount() {
    this.fetchData();
    this.timer = setInterval(() => {
      this.fetchData();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  render() {
    const { attestationDataArray } = this.state;

    return (
      <div>
        {/* <AdminNavbar /> */}

        <div className="d-flex flex-column p-5 bg-light">
          <div className="row bg-light" style={{ minHeight: "25vh" }}>
            <div className="col-md-6 my-auto">
              <div className="section-title text-left">
                <h2>Attestation Records</h2>
              </div>
              <div className="text-muted text-left ">
                List of all the users that can issue a certificate
              </div>
            </div>
            <div className="col-md-6 my-auto p-2 d-flex flex-row justify-content-end">
              <Link
                type="button"
                to={{ pathname: "/new-user" }}
                style={{
                  padding: "2vmin",
                  backgroundColor: "#170a2a",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                Add New <i className="fas fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className=" row">
            <div className="col-md-12">
              <MaterialTable
                title={" "}
                columns={[
                  {
                    title: "Serial#",
                    render: (rowData) => {
                      return (
                        <div>
                          {this.state.attestationDataArray.findIndex(
                            (x) => x.name === rowData.name
                          ) + 1}
                        </div>
                      );
                    },
                  },
                  { title: "Name", field: "name" },
                  { title: "Contact", field: "conatctNumber" },
                  { title: "Tracking Number", field: "trackingNumber" },
                  { title: "Attestation Type", field: "attestationType" },
                  { title: "Recieved Data", field: "receivedDate" },
                  {
                    title: "Status",
                    field: "attestationStatus",
                  },
                  {
                    title: "Edit",
                    render: (rowData) => (
                      <Link
                        to={{
                          pathname: "/edit-user",
                          state: {
                            data: rowData,
                          },
                        }}
                        style={{ color: "#000428", fontSize: "3vmin" }}
                      >
                        <EditIcon />
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "3vmin" }}
                        ></i>
                      </Link>
                    ),
                  },
                ]}
                data={attestationDataArray}
                options={{
                  filtering: true,
                  search: false,
                  headerStyle: {
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderBottom: "1px solid light-grey",
                  },
                  rowStyle: {
                    paddingLeft: 20,
                  },
                }}
                icons={{
                  Filter: React.forwardRef(() => <SearchIcon />),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
