import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import TextInputGroup from "../layout/TextInputGroup";
import classnames from "classnames";
import { Jumbotron } from "react-bootstrap";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import axios from "axios";
configureAnchors({ offset: -10, scrollDuration: 450 });
export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      CNIC: "",
      email: "",
      phoneNo: "",
      message: "",
      success: false,
      error: {},
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    const { fullName, CNIC, email, phoneNo, message } = this.state;

    e.preventDefault();
    let formIsValid = true;
    let newErrors = {};
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (fullName.trim() === "") {
      newErrors = { ...newErrors, fullName: "Full Name is required" };
      formIsValid = false;
    }
    if (CNIC.trim() === "") {
      newErrors = {
        ...newErrors,
        CNIC: "CNIC is required",
      };
      formIsValid = false;
    } else if (CNIC.trim().length !== 13) {
      newErrors = {
        ...newErrors,
        CNIC: "CNIC length: 13-digit",
      };
      formIsValid = false;
    }

    if (email.trim() === "") {
      newErrors = { ...newErrors, email: "Email is required" };
      formIsValid = false;
    } else if (!email.match(mailformat)) {
      newErrors = { ...newErrors, email: "Enter Valid Email" };
      formIsValid = false;
    }
    if (phoneNo.trim() === "") {
      newErrors = { ...newErrors, phoneNo: "Phone No. is required" };
      formIsValid = false;
    }
    if (message.trim() === "") {
      newErrors = { ...newErrors, message: "Message is required" };
      formIsValid = false;
    }

    if (!formIsValid) {
      this.setState({
        error: newErrors,
      });
      return;
    }
    this.setState({ error: {} });
    axios
      .post(process.env.REACT_APP_API_URL + "/users/contactFormMail", {
        fullName: fullName,
        CNIC: CNIC,
        email: email,
        phoneNo: phoneNo,
        message: message,
      })
      .then((res) => {
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({ success: false });
        }, 5000);
      })
      .catch((err) => {});
  };
  render() {
    const {
      fullName,
      CNIC,
      email,
      phoneNo,
      message,
      error,
      success,
    } = this.state;
    return (
      <ScrollableAnchor id={"contact"}>
        <Jumbotron>
          <div className="container">
            <div className="row my-auto">
              <div className="col-md-5 my-auto text-left">
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  animateOut="fadeOut"
                  duration={2}
                >
                  <div className="section-title text-uppercase lead">
                    Be in Touch
                  </div>
                  <div className="section-text mt-3">
                    <i className="fas fa-map-marker-alt" /> <b>Address:</b>
                    <div className="mt-3 mb-3">
                      Flat No.1, Plot.C-7, Street 3, <br />
                      Ghauri VIP Khanna Dak, Islamabad.
                    </div>
                  </div>

                  <div className="section-text ">
                    <i className="fas fa-envelope-square" /> <b>Email: </b>
                    <div className="mt-3 mb-3">contact@eits.org.pk</div>
                  </div>

                  <div className="section-text">
                    <i className="fas fa-mobile-alt" /> <b>Phone:</b>
                    <div className="mt-3 mb-3">
                      0346-8989677 <br /> 0336-5661032
                    </div>
                  </div>
                </ScrollAnimation>
              </div>

              <div className="col-md-7 float-right">
                <ScrollAnimation
                  animateIn="fadeInRight"
                  animateOut="fadeOut"
                  duration={2}
                >
                  <div className="card mb-3 text-left">
                    <div className="card-body">
                      {success && (
                        <div className="row mb-3" style={{ color: "green" }}>
                          <div className="col-lg-12">
                            <i className="fas fa-check"></i> Message Sent. We'll
                            get back to you shortly
                          </div>
                        </div>
                      )}
                      <form>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <TextInputGroup
                              name="fullName"
                              value={fullName}
                              placeholder="Enter Full Name"
                              label="Full Name"
                              onChange={this.onChange}
                              error={error.fullName}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <TextInputGroup
                              name="CNIC"
                              value={CNIC}
                              type="number"
                              placeholder="Enter CNIC"
                              label="CNIC"
                              onChange={this.onChange}
                              error={error.CNIC}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <TextInputGroup
                              name="email"
                              type="email"
                              value={email}
                              placeholder="example@gmail.com"
                              label="Enter Email"
                              onChange={this.onChange}
                              error={error.email}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <TextInputGroup
                              name="phoneNo"
                              type="number"
                              value={phoneNo}
                              placeholder="Enter Phone number"
                              label="Phone Number"
                              onChange={this.onChange}
                              error={error.phoneNo}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-lg-12">
                            {/* {//label htmlFor={message}} */}
                            <label>Your Message:</label>
                            <textarea
                              onChange={this.onChange}
                              style={{ height: "9.2em" }}
                              value={message}
                              className={classnames(
                                "form-control form-control-md",
                                {
                                  "is-invalid": error.message,
                                }
                              )}
                              name="message"
                              placeholder="Your Message *"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12 my-auto">
                            <input
                              type="button"
                              onClick={this.onSubmit}
                              value="Submit"
                              className="btn btn-block"
                              style={{
                                backgroundColor: "#170a2a",
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </Jumbotron>
      </ScrollableAnchor>
    );
  }
}
