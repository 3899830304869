import React from "react";
import PropType from "prop-types";
import classnames from "classnames";

const TextInputGroup = ({
  label,
  name,
  value,
  type,
  placeholder,
  onChange,
  list,
  listArr,
  error,
  disabled,
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        className={classnames("form-control form-control-md", {
          "is-invalid": error,
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        list={list}
        autoComplete="off"
        disabled={disabled}
      />
      {list && (
        <datalist id={list} onChange={onChange}>
          {listArr.map((val) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </datalist>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default TextInputGroup;

TextInputGroup.PropType = {
  name: PropType.string.isRequired,
  label: PropType.string.isRequired,
  value: PropType.string.isRequired,
  placeholder: PropType.string.isRequired,
  onChange: PropType.func.isRequired,
  type: PropType.string,
  list: PropType.string,
  listArr: PropType.array,
  error: PropType.string,
};

TextInputGroup.defaultProp = {
  type: "text",
  list: "",
  listArr: "",
  Date: "",
};
