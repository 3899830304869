import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./css/nav.css";

class Nav1 extends Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          background: "linear-gradient(-90deg, #170a2a 55%, #86c2c2 )",
          padding: "13px",
        }}
        variant="dark"
        sticky="top"
        className="mainNav"
      >
        <Navbar.Brand className="brand" href="#top">
          <div className="row d-flex ml-3 mr-3 flex-row align-items-center">
            {"   "} EITS Attestation Services
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="nav-link ml-auto text-uppercase">
            <Link className="nav-link" to={{ pathname: "/" }}>
              Home
            </Link>
            <Nav.Link className="nav-link" href="/#about">
              About
            </Nav.Link>
            <NavDropdown
              title="Services"
              id="collasible-nav-dropdown d-print-inline-block"
              style={{ color: "rgba(255,255,255,0) !important" }}
            >
              <NavDropdown.Item href="/omanembassy">
                OMAN AMBASSY
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/qatarembacypage">
                MALAYSIA EMBASSY
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/bahrainembassy">
                BAHRAIN EMBASSY
              </NavDropdown.Item>
              <NavDropdown.Item href="/deeniasnad">DeeniAsnad</NavDropdown.Item>
              <NavDropdown.Item href="/ibcc">IBCC ATTESTATION</NavDropdown.Item>
              <NavDropdown.Item href="/uaeembassy">
                UAE EMBASSY
              </NavDropdown.Item>

              {/* <NavDropdown.Item href="/visaservicespage">
                SAUDI ARABIA VISA SERVICES
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/ministryofforiegnaffair">
                MINISTRY OF FORIEGN AFFAIRS
              </NavDropdown.Item>
              <NavDropdown.Item href="/qatarembassy">
                QATAR EMBASSY ATTESTATION
              </NavDropdown.Item>
              <NavDropdown.Item href="/saudiembassydegree">
                SAUDI EMBASSY DEGREE ATTESTATION
              </NavDropdown.Item>
              <NavDropdown.Item href="/saudiembassymarriage">
                SAUDI EMBASSY MARRIAGE CERTIFICATE
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link className="nav-link" href="/#contact">
              Contact
            </Nav.Link>
            <Link
              className="nav-link"
              style={{ background: "blue" }}
              to={{ pathname: "/tracking" }}
            >
              Track Document
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Nav1;
