import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider, Consumer } from "./components/Context";
import {
  LogoutRoute,
  ProtectedRouteAdmin,
} from "./components/functions/protectedRoute";
import jwt_decode from "jwt-decode";
import axios from "axios";
import setAuthToken from "./components/functions/setAuthToken";

import "./App.css";

import AddUser from "./components/Auth/AddUser";
import Contact from "./components/Contact";
import Users from "./components/Auth/Users";
import EditUser from "./components/Auth/EditUser";
import Login from "./components/Auth/Login";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const Home = React.lazy(() => import("./components/Home"));
const IBCCPage = React.lazy(() => import("./components/IBCCPage"));
const UAEPage = React.lazy(() => import("./components/UAEPage"));
// const VisaServices = React.lazy(() => import("./components/VisaServicesPage"));
const MOFA = React.lazy(() => import("./components/MinistryofforiegnPage"));
const QatarPage = React.lazy(() => import("./components/QatarPage"));
const MalaysiaEmbacyPage = React.lazy(() =>
  import("./components/MalaysiaEmbacyPage")
);
const KuwaitEmbacyPage = React.lazy(() =>
  import("./components/KuwaitEmbacyPage")
);
const BahrainEmbacyPage = React.lazy(() =>
  import("./components/BahrainEmbacyPage")
);
const HECPage = React.lazy(() => import("./components/HECPage"));
const EmbassyPage = React.lazy(() => import("./components/EmbassyPage"));
const DeeniAsnadPage = React.lazy(() => import("./components/DeeniAsnadPage"));
const OmanEmbacyPage = React.lazy(() => import("./components/OmanEmbacyPage"));
const ChangePassword = React.lazy(() =>
  import("./components/Auth/ChangePassword")
);
const ForgotPassword = React.lazy(() =>
  import("./components/Auth/ForgotPassword")
);
const AttestationType = React.lazy(() =>
  import("./components/Auth/AttestationTypes")
);
const AddAttestation = React.lazy(() =>
  import("./components/Auth/AddAttestation")
);
const EditAttestation = React.lazy(() =>
  import("./components/Auth/EditAttestationType")
);
const SaudiEmbacyMarriage = React.lazy(() =>
  import("./components/SaudiEmbacyMarriage")
);
const SaudiEmbacyDegree = React.lazy(() =>
  import("./components/SaudiEmbacyDegree")
);
const TrackingPage = React.lazy(() => import("./components/TrackingPage"));
const AboutPage = React.lazy(() => import("./components/AboutPage"));
const AdminNavbar = React.lazy(() => import("./components/AdminNavbar"));

class App extends Component {
  state = {
    isLoggedIn: false,
  };

  componentDidMount = () => {
    if (sessionStorage.getItem("jwtToken") !== "undefined") {
      this.setState({ isLoggedIn: sessionStorage.getItem("jwtToken") });
    }
  };

  checkLogin = (dispatch) => {
    let token = sessionStorage.getItem("jwtToken");
    if (token) {
      let userEmail = sessionStorage.getItem("userEmail");
      setAuthToken(token);
      let decoded = jwt_decode(token);
      axios
        .post(process.env.REACT_APP_API_URL + "/admiAuth/type", {
          id: decoded.id,
        })
        .then((res) => {
          decoded = {
            ...decoded,
            email: userEmail,
            type: res.data.data[0].userType,
          };
          dispatch({
            type: "SET_CURRENT_USER",
            payload: decoded,
          });
          this.setState({ isLoggedIn: false });
        });
    }
  };

  render() {
    return (
      <Provider>
        <Consumer>
          {(value) => {
            if (this.state.isLoggedIn) {
              this.checkLogin(value.dispatch);
            }
            return (
              <Router>
                <Suspense fallback={loading()}>
                  <div className="App">
                    {value.isAuthenticated ? <AdminNavbar /> : <div />}

                    <Switch>
                      <LogoutRoute exact path="/" component={Home} />
                      <LogoutRoute
                        exact
                        path="/aboutus"
                        component={AboutPage}
                      />
                      <LogoutRoute exact path="/contact" component={Contact} />
                      <LogoutRoute
                        exact
                        path="/tracking"
                        component={TrackingPage}
                      />
                      <LogoutRoute
                        exact
                        path="/kuwaitembassy"
                        component={KuwaitEmbacyPage}
                      />
                      <LogoutRoute
                        exact
                        path="/omanembassy"
                        component={OmanEmbacyPage}
                      />
                      <LogoutRoute
                        exact
                        path="/bahrainembassy"
                        component={BahrainEmbacyPage}
                      />
                      <LogoutRoute
                        exact
                        path="/qatarembassy"
                        component={QatarPage}
                      />
                      <LogoutRoute exact path="/ibcc" component={IBCCPage} />
                      <LogoutRoute exact path="/hec" component={HECPage} />
                      <LogoutRoute
                        exact
                        path="/malaysiaembassy"
                        component={MalaysiaEmbacyPage}
                      />
                      <LogoutRoute
                        exact
                        path="/deeniasnad"
                        component={DeeniAsnadPage}
                      />
                      <LogoutRoute
                        exact
                        path="/saudiembassydegree"
                        component={SaudiEmbacyDegree}
                      />
                      <LogoutRoute
                        exact
                        path="/saudiembassymarriage"
                        component={SaudiEmbacyMarriage}
                      />
                      <LogoutRoute
                        exact
                        path="/embassyattestation"
                        component={EmbassyPage}
                      />
                      <LogoutRoute
                        exact
                        path="/uaeembassy"
                        component={UAEPage}
                      />
                      <LogoutRoute
                        exact
                        path="/ministryofforiegnaffair"
                        component={MOFA}
                      />
                      {/* <LogoutRoute
                        exact
                        path="/visaservicespage"
                        component={VisaServices}
                      /> */}

                      <LogoutRoute exact path="/login" component={Login} />
                      <ProtectedRouteAdmin
                        exact
                        path="/dashboard"
                        component={Users}
                      />

                      <ProtectedRouteAdmin
                        exact
                        path="/attestation-types"
                        component={AttestationType}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path="/new-attestation"
                        component={AddAttestation}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path="/change-password"
                        component={ChangePassword}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path="/edit-attestation"
                        component={EditAttestation}
                      />

                      <LogoutRoute
                        exact
                        path="/forget-password"
                        component={ForgotPassword}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path="/edit-user"
                        component={EditUser}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path="/new-user"
                        component={AddUser}
                      />

                      {/* <Route component={NotFound} /> */}
                    </Switch>
                  </div>
                </Suspense>
              </Router>
            );
          }}
        </Consumer>
      </Provider>
    );
  }
}

export default App;
