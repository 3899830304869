import React, { Component } from "react";
import { Redirect } from "react-router";
import TextInputGroup from "../../layout/TextInputGroup";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serial: "",
      contact: "",
      name: "",
      startDate: new Date().toJSON().substring(0, 10),
      CNIC: "",
      statusID: 1,
      attestationTypeList: [],
      attestionstatusList: [],
      attestationTypeID: 0,
      redirect: false,
      success: false,
      error: {},
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, errors: false });
  };

  handleChange = (event) => {
    this.setState({
      startDate: event.target.value,
    });
  };
  fetchattestiontype = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/attestationType/getAttestationTypes"
      )
      .then((res) => {
        let attestionList = res.data.data;

        this.setState({ attestationTypeList: attestionList });
      })
      .catch((err) => {});
  };
  fetchstatus = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/attestationStatus/getAttestationStatus"
      )
      .then((res) => {
        let statusList = res.data.data;

        this.setState({ attestionstatusList: statusList });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.fetchstatus();
    this.fetchattestiontype();
  }
  onSubmit = (e) => {
    const {
      contact,

      name,

      statusID,
      startDate,

      attestationTypeID,
    } = this.state;

    e.preventDefault();
    let formIsValid = true;
    let newErrors = {};
    if (name === "") {
      newErrors = { ...newErrors, name: "Name is required" };
      formIsValid = false;
    }
    if (contact === "") {
      newErrors = { ...newErrors, contact: "Contact is required" };
      formIsValid = false;
    }

    if (startDate === "") {
      newErrors = { ...newErrors, startDate: "Date is required" };
      formIsValid = false;
    }

    if (statusID == 0) {
      newErrors = { ...newErrors, statusID: "Status is required" };
      formIsValid = false;
    }
    if (attestationTypeID == 0) {
      newErrors = { ...newErrors, attestationTypeID: "Status is required" };
      formIsValid = false;
    }

    if (!formIsValid) {
      this.setState({
        error: newErrors,
      });
      return;
    }

    let req = {
      userID: 1,
      statusID: statusID,
      attestationTypeID: attestationTypeID,
      name: name,

      receivedDate: startDate,

      conatctNumber: contact,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "/attestation/addAttestationRecord",
        req
      )
      .then((res) => {
        this.setState({ error: {}, success: true });
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 1000);
      })
      .catch((err) => {});
  };

  render() {
    const {
      name,
      contact,

      statusID,
      attestationTypeID,
      success,

      redirect,

      attestionstatusList,
      attestationTypeList,
      error,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/dashboard",
          }}
        />
      );
    }
    return (
      <div>
        <section
          style={{ minHeight: "75vh" }}
          className="d-flex align-items-center my-auto p-5 bg-light"
        >
          <div className="container">
            <div className="row my-auto">
              <div className="col-md-4 my-auto ">
                <div className="section-title text-left col-md-11 mt-3 ">
                  <h2>Add new attestation record</h2>
                </div>

                {success ? (
                  <div
                    className=" text-left col-md-11 mb-3 mt-2"
                    style={{ color: "green", fontSize: "2.5vmin" }}
                  >
                    Attestation Record added Successfully{" "}
                    <i className="fas fa-check" />
                  </div>
                ) : (
                  <div className="text-muted text-left col-md-11 mb-3">
                    Please fill in correct details
                  </div>
                )}
              </div>

              <div className="col-md-8 mt-3">
                <div className="card mb-3 text-left">
                  <div className="card-body ml100 mr100">
                    <div className="row">
                      <div className="col-md-12 text-muted col-sm-12 mb-3"></div>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-sm-12">
                          <TextInputGroup
                            name="name"
                            value={name}
                            placeholder="Ali Raza"
                            label="Name"
                            onChange={this.onChange}
                            error={error.name}
                          />
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <TextInputGroup
                            name="contact"
                            value={contact}
                            placeholder="Enter Contact"
                            label="Contact Number"
                            onChange={this.onChange}
                            error={error.contact}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <TextInputGroup
                            type="date"
                            name="startDate"
                            label="Select Date"
                            value={this.state.startDate}
                            placeholder="select Date"
                            onChange={this.handleChange}
                            error={error.startDate}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <label htmlFor={attestationTypeID}>
                            Attestation Type
                          </label>
                          <select
                            onChange={this.onChange}
                            value={attestationTypeID}
                            name="attestationTypeID"
                            className={classnames(
                              "form-control form-control-md",
                              {
                                "is-invalid": error.attestationTypeID,
                              }
                            )}
                          >
                            <option value="">Select Attestation Type</option>
                            {attestationTypeList.map((item, index) => (
                              <option
                                key={index}
                                value={item.attestationTypeID}
                              >
                                {item.attestationType}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <label htmlFor={statusID}>Order Status</label>
                          <select
                            onChange={this.onChange}
                            value={statusID}
                            name="statusID"
                            className={classnames(
                              "form-control form-control-md",
                              {
                                "is-invalid": error.statusID,
                              }
                            )}
                          >
                            <option value="">Select Status</option>
                            {attestionstatusList.map((item, index) => (
                              <option key={index} value={item.statusID}>
                                {item.attestationStatus}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-sm-12 my-auto">
                          <button
                            className="btn btn-block"
                            onClick={() => {
                              this.setState({ redirect: true });
                            }}
                            style={{
                              backgroundColor: "#170a2a",
                              color: "white",
                              textTransform: "uppercase",
                              marginTop: "3vh",
                            }}
                          >
                            <i className="fas fa-times" /> Cancel
                          </button>
                        </div>
                        <div className="col-lg-6 col-sm-12 my-auto">
                          <button
                            className="btn btn-block"
                            type="button"
                            onClick={this.onSubmit}
                            style={{
                              backgroundColor: "#170a2a",
                              color: "white",
                              textTransform: "uppercase",

                              marginTop: "3vh",
                            }}
                          >
                            <i className="fas fa-check" /> Add New Record
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
