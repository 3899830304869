import React, { Component } from "react";

import isEmpty from "./functions/isEmpty";

export const Context = React.createContext();

const initialState = { isAuthenticated: false, user: {} };
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    default:
      return state;
  }
};
export class Provider extends Component {
  state = {
    TabData: [
      {
        key: "#1",
        label: "Saftey",
        data: ["Safety & Security Management"],
      },
      {
        key: "#2",
        label: "Junior and Senior Programs (GD)",
        data: [
          "Library Information Science & Networking (LIS)",
          "Digital Library Information System",
          "Information Technology & LIS",
          "Special Education",
          "Education Planning & Management",
          "Interactive English Language",
          "English Language Development",
          "Spoken English Proficiency",
          "Business English",
          "Usul-Ud-Din",
          "Office Administration",
          "International Montessori Teachers Training",
          "Educational Physiology",
        ],
      },
      {
        key: "#3",
        label: "Hotel & Catering Industry (GD)",
        data: [
          "Hotel Management & Tourism",
          "Travel Tourism & Hospitality Management",
          "Hospitality & Hotel Management",
          "Recreation & Leisure Management",
          "Tourism Management",
          "Advanced Hospitality & Hotel Management",
          "Fast Food Management",
          "Hotel Catering Management",
          "Food & Beverages Management",
          "Guest House Management",
          "Hotel Management",
          "Restaurant Management",
          "Snack Bar & Café Management",
          "Hospitality Sales & Marketing",
          "Hotel Management Marketing & Human Resources",
          "Watering Management",
          "Hotel Business Development",
          "Bed & Breakfast Management",
          "Hotel & Motel Management & Operations",
        ],
      },
      {
        key: "#4",
        label: "HRM, HRD, HRP, Project Management (GD)",
        data: [
          " Human Resource Management",
          "Human Resource Development",
          "Human Resource Practitioner",
          "Managing Human Resource",
          "Professionalism in Human Resource Management",
          "Human Resource & Personal",
          "Project Management",
          "Project Planning & Control",
          "Project Planning & Management",
          "Advanced Project Management",
          "Project Management Information System",
          "Project Management Promotional Strategies",
          "Advanced Project Management",
          "TQM & ISO-9001-2000",
          "Practical Project Management",
          "Project l Management For Business & Technology",
          "Management Development & E-Learning",
          "Leadership In Project Management",
          "Project Implementation Management Effective Project Management",
          "Joint Project Planning",
          "Project Resources Management",
          "Total Quality Management",
        ],
      },
      {
        key: "#5",
        label: "Marketing Management (GD)",
        data: [
          "International Marketing",
          "Marketing Management",
          "Marketing Finance Management (DMFM)",
          "Business Administration With Major In Marketing",
          "Business Information System & Marketing",
          "Marketing , Sales & Distribution MGT",
          "Business Market Management (Planning & Implementing)",
          "Principles of Marketing",
          "Competitive Marketing Strategy",
          "Micro /Macro Marketing",
          "Modern Marketing Technologies",
          "Customer Relationship Management",
          "Marketing Target Management",
          "Advertising , Sales Promotions & Public Relations",
          "Internet Marketing Management",
          "Logistics Marketing Management",
          "Retailer , Wholesale, Corporate & International Marketing",
          "International Trading & Bonded Warehouses",
          "Advanced Economics",
          "Managerial Economics",
          "Advanced Macroeconomics",
          "Customer Relationship Management & E–Marketing Strategies",
          "Personal Energy Management (Advanced Course)",
          "Sociology Management",
          "Income Tax Laws Management",
          "Marketing Distribution Management",
          "For Hospitality & Tourism",
          "Managing Marketing & Sustainability Management",
          "Sales & Marketing",
          "Salesmanship",
          "Sales Management",
          "Organizational Behavior",
          "Productions & Operations Management",
          "The Management & Control Of Quality",
          "Quality Management & System (ISO-9000)",
        ],
      },

      {
        key: "#7",
        label: "Import & Export Procurement Logistics & Inventory (PD)",
        data: [
          "Import & Export Management",
          "Procurement Management",
          "Procurement, Logistic & Inventory",
          "Control Management",
          "Inventory & Material Management",
          "Logistics, Supply Chain Management",
          "Store Keeping & Purchasing",
          "Materials Management",
          "Introduction To Intellectual Property",
          "Shipping Transport & Management",
          "Industrial Development Inventory & Management",
          "Procurement, Stores & Material Management",
          "Modern Inventory Control & Management",
          "Stores Management",
          "Supervisory Management",
          "Transport Management",
        ],
      },
      {
        key: "#8",
        label:
          "Business Administration /Management, Business Management Development Studies (GD)",
        data: [
          " Business Accounting",
          "Development Studies",
          "Business Administration",
          "Management Control",
          "Operations Management",
          "Knowledge Management",
          "Management For Managers /Executives",
          "Business Organization & Management",
          "The Call Centre Management",
          "Competency Based Management Development",
          "Competency Based Reward Management",
          "Effective Strategic & Investment Management",
          "Managing & Strategic Management",
          "Wholesaling Management",
          "Globalization & Management",
          "Business Communication Today",
          "Business Communication",
          "Effective Business Communication & Role Of Information Technology",
          "New Era Investment Planning & Management",
          "Business Administration",
          "Advanced Business Administration",
          "Business Administration & Management",
          "Business Studies",
          "Quality & Reward Management",
          "Global Business Today",
          "Introductory Course In Digital Logic Design With VHDL",
          "Business Planning",
          "Events Management",
          "General Management",
          "Information Management",
          "International Human Resource Management",
          "Market Relations",
          "Mediation & Negotiation",
          "Money Management",
          "Personal Management",
          "Press Publicity & Profile Rasing",
          "Property Investment",
          "Retail Management",
          "Business Management & Industrial Economics",
          "Development Psychology",
          "Environment Legislation & Policy",
        ],
      },
      {
        key: "#9",
        label:
          "Public Administration & Leadership Courses & Textile Industrial Courses (GD)",
        data: [
          "Leadership (DLEP)",
          "Leadership Development",
          "Leadership for Leaders",
          "Public Administration & Leadership",
          "Textile Management",
          "Textile Processing Management",
        ],
      },
      {
        key: "#12",
        label: " Sports Science (GD)",
        data: [
          "Exercise physiology",
          "Fitness Management",
          "Personal Fitness Trainers",
          "Sports Electrotherapy",
          "Sports Physiology",
          "Sport Therapist",
          "Sport nutrition",
        ],
      },
      {
        key: "#13",
        label: "Conservation & The Environment (PD)",
        data: [
          "Aquarium & Fish keeping",
          "Conservation",
          "Ecology",
          "Environmental Legislation & Policy",
          "Environmental Waste Management",
          "Landscaping",
          "Stable Management",
          "Environmental Management",
        ],
      },
      {
        key: "#14",
        label: "Hobbies, Trades, Diy , Home, Photo Journalism & leisure (GD)",
        data: [
          "Calligraphy",
          "Meteorology",
          "Photo journalism",
          "Digital Photography",
          "Landscape Photography",
        ],
      },
      {
        key: "#15",
        label:
          "Construction Management, Surveying Management, Civil technology & Land Surveying (TP Vocational & Technical)",
        data: [
          " Construction Management",
          "Modern Construction Management",
          "Building Technician",
          "Civil & Architecture Technology",
          "Civil & Architecture Technology",
          "Building Construction Surveyor",
          "Land Surveying",
          "Surveying Management",
          "Quantity Surveying",
          "Civil Drafting",
          "Multi Weldor",
          "X-Rays Welding",
          "Civil Surveying (2 Year)",
          "Auto Cad",
          "Electrical Technician",
          "Electronics Technician",
        ],
      },
      {
        key: "#16",
        label:
          "Engineering, Sanitary Inspector, Electrical Engineering, Civil Engineering Mechanical Engineering (EP-HSE)",
        data: [
          "Engineering Management",
          "Environmental Engineering",
          "HSC Engineering",
          "Sanitary Inspector",
          "Process Engineering Modern Plant Operation",
          "Process Engineering Modern Plant",
          "Operation & Waste Water Treatment",
          "Civil Engineering Construction",
          "Civil Engineering Construction (2 YEAR)",
          "Mechanical Engineering (Diesel, Gas, Heat Engines)",
          "Mechanical Engineering (Oil & Gas)",
          "Mechanical & Electrical Engineering (Mechatronics)",
          "Electrical Instrumentation Engineering",
          "Auto Diesel Engineering",
          "Electrical Engineering",
          "Electrical Engineering (2 YEAR)",
          "Civil Road Laboratory Technician",
          "Material Engineering",
          "Chemical Engineering",
          "Oil & Gas Technology",
          "Petroleum Engineering",
          "Road Engineering",
          "Railway Engineering",
          "Bridge Engineering",
          "Bridge Engineering",
          "Tunnel Engineering",
          "Harbor –Duck Engineering",
          "Telecommunication & System Engineering",
          "Telecommunication & Networking",
          "Wireless Communication System",
          "Networking",
          "Optical Fiber Communication",
          "Mobile, Satellite Communication",
          "Telecommunication & Date Resource Management",
          "Surveying & Leveling",
          "Hazardous Solid Waste Testing",
          "Control System Engineering",
        ],
      },

      {
        key: "#18",
        label:
          "CFA & CTM Portfolio Management & Managerial Effectiveness Diploma Progams",
        data: [
          "Focus Areas",
          "Subject Vise Wavers",
          "Financial Analyst",
          "Financial Market Management",
          "Treasury & Forex Management",
          "Portfolio Management",
          "Total Quality Management",
          "Performance Management",
          "Insurance Management",
          "Economics (Principal & Practices)",
          "Financial Management",
          "Management & Finance",
          "Financial Management",
          "Business Accounting",
          "Islamic Banking & Finance",
          "Banking Management",
          "Commercial Banking & Stock Exchanges",
          "General Banking",
          "Money & Banking",
          "Advanced Accounting",
          "Business Accounting & Finance",
          "Business & Finance Management",
          "Financial Management & Investment",
          "Multinational Financial Management",
          "Corporate Finance",
          "Finance Planning In Business Planning",
          "Managerial Finance",
          "Audit & Account Management",
          "Accounting Management",
          "Working Capital Management",
          "Entrepreneurship",
          "The WTO & The Multilateral Trading System",
          "Financial Reporting & Analysis",
          "CTM In Treasury Management",
          "Systematic Investment Management",
          "Quantitative Methods",
          "Strategic Management",
          "Strategic Financial Management",
        ],
      },
      {
        key: "#19",
        label:
          "Rescue & Emergency Programs IN Disaster, Rescue, Crises & Emergency Management",
        data: [
          "Rescue & Disaster Management",
          "Disaster & Emergency Management",
          "Terrorism & Chemical ,Biological",
          "Radiological & Nuclear",
          "Crises Management & International",
          "Disasters Management",
          "Managerial Executive Diploma Courses In E-MBA",
          "Executive Manager In Business Administration",
          "Executive Manager In Business Human Resource",
          "Executive Manager In Hotel Industry",
          "Executive Manager In Office Administration & Management",
          "Executive Manager In Project Management",
          "Executive Manager In Project Planning & Management",
          "Executive manager In Environmental Management",
          /* "Women’s Health Practitioner",
          "Health & Beauty Management", */
          "Hairdressing & Saloon Management",
          "Fashion Designing & Tailoring",
        ],
      },
      {
        key: "#20",
        label:
          "Beautician & Skin Care Programs Fashion Designing, Interior Decoration",
        data: [
          "Beauty Parlor Beautician",
          "Women’s Stress",
          "Beauty Saloon Management",
          "Beauty Therapist",
        ],
      },
      // {
      //   key: "#21",
      //   label: "Health Care Management Programs Clinical, Preventive",
      //   data: [
      //     "Patient Care Technician/Dispenser",
      //     "Medical Laboratory Technician",
      //     "Physiotherapy Technician",
      //     "Medical Technician",
      //     "Emergency Medical Technician",
      //     "Medical Assistant",
      //     "Nursing Management",
      //     "Clinical Nursing",
      //     "Pharmacy Technician",
      //     "X-Ray Technician Ultra Sound Technician",
      //     "Dental Technician",
      //     "Dental hygienist",
      //     "Dental Assistant",
      //     "Occupational Medicine",
      //     "Cardiology",
      //     "Diagnostic Radiology",
      //     "Gynecologist / Child Specialist",
      //     "Clinical Laboratory Diagnostics",
      //     "Junior Clinical Technician (Surgery)",
      //     "Gynae Health Worker ( Midwifery)",
      //     "Lady Health Visitor",
      //     "Electro Encephalography",
      //     "Medical Emergency & First Aid",
      //     "Pain Management",
      //     "Nursing Management",
      //     "Primary Health Care",
      //     "Clinical Nutrition",
      //     "Clinical Microbiology",
      //     "Clinical Food Microbiology",
      //     "Medical Education",
      //     "Ultrasound Diagnostic",
      //     "Urology"
      //   ]
      // }
    ],
    NewsData: [
      {
        title: "Main Title 1",
        subtitle: "Subtitle 1",
      },
      {
        title: "Main Title 2",
        subtitle: "Subtitle 2",
      },
      {
        title: "Main Title 3",
        subtitle: "Subtitle 3",
      },
      {
        title: "Main Title 4",
        subtitle: "Subtitle 4",
      },
      {
        title: "Main Title 5",
        subtitle: "Subtitle 5",
      },
    ],
    isAuthenticated: false,
    user: {},
    dispatch: (action) => {
      this.setState((state) => reducer(state, action));
    },
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;
