import React, { Component } from "react";
import { Redirect } from "react-router";
// import { Link } from "react-router-dom";
import "../css/login.css";
import { Consumer } from "../Context";
import axios from "axios";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "../functions/setAuthToken";
import TextInputGroup from "../../layout/TextInputGroup";
import Nav1 from "../Nav1";
// import Header from "../Header";
import Footer from "../Footer";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
      redirect: false,
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loginUser = (user, dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/admiAuth/login", user)
      .then((res) => {
        const { token, email } = res.data;
        sessionStorage.setItem("jwtToken", token);
        sessionStorage.setItem("userEmail", email);
        setAuthToken(token);
        let decoded = jwt_decode(token);

        axios
          .post(process.env.REACT_APP_API_URL + "/admiAuth/type", {
            id: decoded.id,
          })
          .then((res) => {
            decoded = {
              ...decoded,
              email: email,
              type: res.data.data[0].userType,
            };

            dispatch(this.setCurrentUser(decoded));
          })
          .catch((err) => err);
      })
      .catch((error) => {
        this.setState({
          errors: { password: "Username or password is incorrect" },
        });
        dispatch({
          type: "",
          payload: error.data,
        });
      });
  };

  setCurrentUser = (decoded) => {
    return {
      type: "SET_CURRENT_USER",
      payload: decoded,
    };
  };

  onSubmit = (dispatch) => {
    const { email, password } = this.state;
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email.trim() === "") {
      this.setState({ errors: { email: "Email is required" } });
      return;
    } else if (!email.match(mailformat)) {
      this.setState({ errors: { email: "Enter a valid email address" } });
      return;
    } else if (password.trim() === "") {
      this.setState({ errors: { password: "Password is required" } });
      return;
    } else {
      this.loginUser({ email: email, password: password }, dispatch);

      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 3000);
    }
  };

  render() {
    const { email, password, errors, redirect } = this.state;
    if (redirect) {
      return <Redirect push to={{ pathname: "/dashboard" }} />;
    }
    return (
      <Consumer>
        {(value) => {
          const { dispatch } = value;
          if (redirect) {
            if (value.user.type === "Admin")
              return <Redirect push to="/dashboard" />;
          }

          return (
            <>
              <Nav1 />
              <section
                style={{ minHeight: "100vh" }}
                className="d-flex bg-light align-items-center my-auto p-5 "
              >
                <div className="container">
                  <div className="row my-auto">
                    <div className="col-md-6 ">
                      <div className="section-title text-left row mt-3 ">
                        <h3>Login Portal </h3>
                      </div>
                      <div className="text-muted text-left row mb-3">
                        Enter your email and password
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card mb-3 text-left">
                        <div className="card-body ml100 mr100">
                          <form>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12">
                                <TextInputGroup
                                  name="email"
                                  type="email"
                                  value={email}
                                  placeholder="example@gmail.com"
                                  label="Email"
                                  onChange={this.onChange}
                                  error={errors.email}
                                />
                              </div>
                              <div className="col-lg-12 col-sm-12">
                                <TextInputGroup
                                  name="password"
                                  value={password}
                                  type="password"
                                  placeholder="Enter Password"
                                  label="Password"
                                  onChange={this.onChange}
                                  error={errors.password}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 my-auto">
                                <input
                                  type="button"
                                  value="Login"
                                  onClick={this.onSubmit.bind(this, dispatch)}
                                  className="btn btn-block"
                                  style={{
                                    backgroundColor: "#000428",
                                    color: "white",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                          <div className="row mt-2">
                            <div className="col-lg-12 mx-auto">
                              {/* <Link
                                to={{
                                  pathname: "/forget-password",
                                }}
                                style={{ color: "#000428", fontSize: "2vmin" }}
                              >
                                Forgot Password?
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </>
          );
        }}
      </Consumer>
    );
  }
}
